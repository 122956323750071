import { Password, Redo, VpnKey } from '@mui/icons-material';
import axios from 'axios';
import { z } from 'zod';
import {
  Action,
  Resource,
  AnchorAction,
  ButtonAction,
  CardLayout,
  FieldFactory,
  GroupLayout,
  TextInputField,
  SidebarLayout,
} from '@/classes';
import UserAccess from '@/components/Users/UserAccess';
import { UserDetail, userPayloadSchema } from '@/types';
import { getBulkDisableAction, getSingleDisableAction } from '@/utils/disabled';
import getApiUrl from '@/utils/getApiUrl';
import { getEventableConfigForResource } from '@/utils/resources';

const POOLS = ['proof', 'release', 'vectorize', 'digitize', 'sew_out', 'connect_leads'] as const;

export default function users(): Resource<UserDetail> {
  return new Resource<UserDetail>('Users')
    .withSchema(userPayloadSchema)
    .withBulkActions([getBulkDisableAction('Users', 'users')])
    .setCanExport()
    .getSingleActionsUsing((values) => {
      const actions: Action[] = [
        new AnchorAction('Login as User', getApiUrl(`/impersonate/${values.id}`))
          .withIcon(VpnKey)
          .withPermission('write:users'),
        new ButtonAction(
          'Update Password',
          ({ dialogs }) => {
            dialogs.prompt({
              title: 'Update Password',
              description: 'Enter a new password for this user',
              fields: [
                new TextInputField('password', 'password').setRequired(),
                new TextInputField('password_confirmation', 'password').setRequired(),
              ],
              schema: z.object({
                password: z.string().min(8),
                password_confirmation: z.string().min(8),
              }),
              onSubmit: (v) => axios.put(`/api/users/${values.id}/password`, v),
            });
          },
          Password,
        ),
      ];

      if (values.has_pending_invite) {
        actions.push(
          new ButtonAction('Resend Invite', ({ showLoading, toast }) => {
            showLoading(axios.post(`/api/users/${values.id}/resend-invite`)).then(() => {
              toast('Invite successfully resent');
            });
          })
            .withIcon(Redo)
            .withPermission('write:users'),
        );
      }
      actions.push(getSingleDisableAction('User', 'users', values));
      return actions;
    })
    .withDefaultFilters({
      is_disabled: false,
    })
    .withInitialColumns([
      'name',
      'email',
      'title',
      'groups',
      'roles',
      'is_disabled',
      'last_activity',
    ])
    .withFilters([FieldFactory.select('pool', POOLS).withFilterKey('filter[pools.pool]')])
    .withFieldsAndColumns([
      new SidebarLayout(
        '',
        [
          new CardLayout('', [
            FieldFactory.text('name').sortable().withColumnSpan(6),
            FieldFactory.email('email').sortable().withColumnSpan(6),
            FieldFactory.text('title').sortable().withColumnSpan(6),
            FieldFactory.hasMany('groups', 'groups').withColumnSpan(6),
          ]),

          new CardLayout('sales', [
            FieldFactory.belongsTo('artist', 'users')
              .filterable('filter[artist_id]')
              .withLabel('Assigned Artist')
              .withColumnSpan(6),
            FieldFactory.hasMany('account_managers', 'users')
              .withLabel('Account Managers')
              .withColumnSpan(6),
            FieldFactory.belongsTo('commission_model', 'commissionModels')
              .withColumnSpan(6)
              .withLabel('Default Commission Model')
              .withHelp('If set, will override fixed percent'),
            FieldFactory.percent('commission_percent')
              .withLabel('Default Commission %')
              .withColumnSpan(6),
          ]),
          new CardLayout('Auto Assigning')
            .withSubheader('Which type of requests should this user be assigned')
            .withFields([
              FieldFactory.list('pools', [
                FieldFactory.select('pool', [
                  'proof',
                  'release',
                  'vectorize',
                  'digitize',
                  'sew_out',
                  'connect_leads',
                ]).withColumnSpan(9),
              ]),
            ]),
          getEventableConfigForResource('users'),
        ],
        [
          new CardLayout('profile', [
            FieldFactory.image('avatar').withColumnSpan(12),
            new GroupLayout('Settings', [
              FieldFactory.boolean('taggable', 'Taggable').filterable().sortable(),
            ])
              .withoutLabel()
              .withColumnSpan(12),
          ]),
          new CardLayout('access', [
            FieldFactory.custom('Access', UserAccess).withColumnSpan(12),
          ]).setCreatable(false),
        ],
      ),
    ])
    .addColumns([
      FieldFactory.belongsTo('business', 'businesses')
        .withHelp('Leave blank for a global user')
        .filterable('filter[business_id]'),
      FieldFactory.select('access_level', {
        0: 'Individual',
        1: 'Manager',
        10: 'Admin',
      }).filterable(),
      FieldFactory.hasMany('roles', 'roles').filterable(),
      FieldFactory.boolean('is_disabled').sortable('disabled_at').filterable('is_disabled'),
      FieldFactory.timestamp('last_activity').sortable(),
      FieldFactory.timestamp('last_logged_in').sortable(),
      FieldFactory.timestamp('created_at').sortable(),
    ]);
}
