import { BrokenImage, Inventory } from '@mui/icons-material';
import axios from 'axios';
import { z } from 'zod';
import { ButtonAction, CustomAction, FieldFactory, Resource } from '@/classes';
import PrintMenu from '@/components/Print/PrintMenu';
import PackItemsField from '@/components/Production/PackItemsField';
import ProductionOrder from '@/components/Production/ProductionOrder';
import ScrapItemsField from '@/components/Production/ScrapItemsField';
import { Order } from '@/types';
import { getOrderColumns } from './orders';

export default function ordersToProduce() {
  return new Resource<Order>('Orders to Produce')
    .withRelations(['items', 'scraps', 'boxes'] as const)
    .getTitleUsing((o) => `Production: ${o.increment_id}`)
    .withApiEndpoint('/api/orders')
    .getSingleActionsUsing((order) => [
      new ButtonAction(
        'Pack Items',
        ({ dialogs, onReloadRecord }) => {
          dialogs
            .drawerPrompt({
              title: 'Pack Items',
              width: 600,
              fields: [FieldFactory.custom('items', PackItemsField)],
              schema: z.object({
                items: z.array(
                  z.object({
                    order_item_id: z.number(),
                    qty: z.coerce.number(),
                  }),
                ),
              }),
              onSubmit: (v) =>
                axios.post(`/api/order-boxes`, {
                  order_id: order.id,
                  items: v.items.filter((i) => i.qty > 0),
                }),
            })
            .then(() => {
              onReloadRecord();
            });
        },
        Inventory,
      ),
      new ButtonAction(
        'Scrap Items',
        ({ dialogs, onReloadRecord }) => {
          dialogs
            .drawerPrompt({
              title: 'Scrap Items',
              width: 600,
              fields: [
                FieldFactory.custom('items', ScrapItemsField),
                FieldFactory.textarea('reason'),
              ],
              schema: z.object({
                items: z.array(
                  z.object({
                    order_item_id: z.number(),
                    qty: z.coerce.number(),
                  }),
                ),
                reason: z.string(),
              }),
              onSubmit: (v) => axios.post(`/api/orders/${order.id}/scraps`, v),
            })
            .then(() => {
              onReloadRecord();
            });
        },
        BrokenImage,
      ),
      new CustomAction('Print', () => <PrintMenu model="order" id={order.id} />),
    ])
    .setReadOnly()
    .setCanExport()
    .withDefaultSort('committed_ship_date')
    .withQueryParams({
      index: {
        to_produce: '1',
      },
      single: {
        with: 'items,scraps',
      },
    })
    .withInitialColumns([
      'increment_id',
      'customer',
      'sales_rep',
      'order_type',
      'status',
      'staged_at',
      'drop_dead_date',
      'committed_ship_date',
    ])
    .withFilters([
      FieldFactory.radio('bucket', {
        all: 'All',
        to_stage: 'To Stage',
        staging: 'Staging',
        staged: 'Staged',
        in_production: 'In Production',
      }).asQuickFilter(),
    ])
    .withColumns(getOrderColumns())
    .editUsing(ProductionOrder);
}
